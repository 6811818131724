import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'

import {v4 as uuidv4} from 'uuid'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'

function getFilterTitle(id) {
  let filterLabel

  switch (id) {
    case 'severity': {
      filterLabel = 'tables.filterBySeverity'
      break
    }
    case 'status': {
      filterLabel = 'tables.filterByStatus'
      break
    }
    case 'type': {
      filterLabel = 'tables.filterByCategory'
      break
    }
    case 'cloudaccount': {
      filterLabel = 'tables.filterByCloudIntegration'
      break
    }
    case 'assignment': {
      filterLabel = 'tables.filterByAssignment'
      break
    }
    case 'assignedto': {
      filterLabel = 'tables.filterByAssignee'
      break
    }
    case 'domainid': {
      filterLabel = 'tables.filterByDomain'
      break
    }
  }

  return (
    <label className="block tracking-wide text-sideral-700 text-xs font-medium mb-1">
      {i18next.t(`${filterLabel}`)}
    </label>
  )
}

export default function FilterMenu({
  setFilterMenuVisibility,
  filterMenuVisibility,
  headerGroups,
  setAllFilters,
  sortBy,
  filters,
  setLoading,
  isLoadingFilters,
  updateUrlPagination,
  updateUrlQueryStrings,
  refreshTableData,
  gotoPage,
}) {
  function shouldRenderCloudFilters() {
    const categoryFilters =
      filters && filters.find(filter => filter.id === 'type')

    if (
      categoryFilters &&
      categoryFilters.value.find(filter => filter.value === 'cloud')
    )
      return true

    return false
  }

  function shouldRenderAssignedToFilters() {
    const assignmentFilters =
      filters && filters.find(filter => filter.id === 'assignment')

    if (
      assignmentFilters &&
      assignmentFilters.value &&
      assignmentFilters.value.value === 'assigned'
    )
      return true

    return false
  }

  function applyFilters() {
    updateUrlQueryStrings(sortBy, filters)
    updateUrlPagination(0)
    gotoPage(0)
    setLoading(true)

    refreshTableData(sortBy)
  }

  return (
    <div className={filterMenuVisibility} id="filter-menu">
      <form
        style={{width: '480px'}}
        className="absolute top-0 right-0 select-none bg-white rounded-md shadow-sm border z-50 p-6 mt-10 ml-4">
        <button
          onClick={e => {
            e.preventDefault()
            setFilterMenuVisibility('hidden')
          }}
          className="icofont-close absolute top-0 right-0 mt-4 mr-4 text-sideral-300"
        />

        {!isLoadingFilters ? (
          <>
            {headerGroups.map(headerGroup => {
              return (
                <div key={uuidv4()} className="flex w-full">
                  <span className="w-full">
                    {headerGroup.headers.map(column => {
                      if (!column.canFilter) return <span key={uuidv4()}></span>
                      if (column.id === 'cloudAccount')
                        return (
                          shouldRenderCloudFilters() && (
                            <div className="mt-6" key={uuidv4()}>
                              {getFilterTitle(column.id.toLowerCase())}
                              <span>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </span>
                            </div>
                          )
                        )

                      if (column.id === 'assignedTo')
                        return (
                          shouldRenderAssignedToFilters() && (
                            <div className="mt-6" key={uuidv4()}>
                              {getFilterTitle(column.id.toLowerCase())}
                              <span>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </span>
                            </div>
                          )
                        )

                      return (
                        <div className="mt-6" key={uuidv4()}>
                          {getFilterTitle(column.id.toLowerCase())}
                          <span>
                            {column.canFilter ? column.render('Filter') : null}
                          </span>
                        </div>
                      )
                    })}
                  </span>
                </div>
              )
            })}

            <div className="flex mt-8 mb-2">
              <div className="flex w-1/2 items-center">
                <SecondaryButton
                  text={i18next.t('people.filters.clearFilters')}
                  onClick={() => setAllFilters([])}
                  size="sm"
                />
              </div>
              <div className="flex justify-end w-1/2">
                <PrimaryButton
                  text={i18next.t('buttons.apply')}
                  onClick={e => {
                    e.preventDefault()
                    setFilterMenuVisibility('hidden')
                    applyFilters()
                  }}
                  theme="blue"
                  type="submit"
                />
              </div>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </form>
    </div>
  )
}

FilterMenu.propTypes = {
  setFilterMenuVisibility: PropTypes.func,
  filterMenuVisibility: PropTypes.string,
  headerGroups: PropTypes.array,
  setAllFilters: PropTypes.func,
  sortBy: PropTypes.array,
  filters: PropTypes.array,
  setLoading: PropTypes.func,
  isLoadingFilters: PropTypes.bool,
  updateUrlPagination: PropTypes.func,
  updateUrlQueryStrings: PropTypes.func,
  refreshTableData: PropTypes.func,
  gotoPage: PropTypes.func,
}
