import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {showAutoSubscriptionModal} from 'actions'
import {DefaultModal} from 'components/partials/DefaultModal'
import {linearGradientStyle} from 'constant'
import giftIcon from '../../res/icons/giftGradientIcon.svg'
import lockIcon from '../../res/icons/lockGradientIcon.svg'
import cardIcon from '../../res/icons/cardGradientIcon.svg'
import cancelIcon from '../../res/icons/cancelGradientIcon.svg'
import {Trans} from 'react-i18next'

export default function AutoSubscriptionModal() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const showAutoSubscriptionModalState = useSelector(
    state => state.showAutoSubscriptionModal
  )

  const onCloseModal = () => dispatch(showAutoSubscriptionModal(false))

  return (
    showAutoSubscriptionModalState && (
      <DefaultModal
        id="autoSubscriptionModal"
        title={i18next.t('sales.autoSubscriptionModal.title')}
        body={
          <>
            <div className="grid grid-cols-2 gap-2">
              <Carditem
                title={i18next.t(
                  'sales.autoSubscriptionModal.body.firstItem.title'
                )}
                description={i18next.t(
                  'sales.autoSubscriptionModal.body.firstItem.description'
                )}
                icon={giftIcon}
              />
              <Carditem
                title={i18next.t(
                  'sales.autoSubscriptionModal.body.secondItem.title'
                )}
                description={i18next.t(
                  'sales.autoSubscriptionModal.body.secondItem.description'
                )}
                icon={lockIcon}
              />
              <Carditem
                title={i18next.t(
                  'sales.autoSubscriptionModal.body.thirdItem.title'
                )}
                description={i18next.t(
                  'sales.autoSubscriptionModal.body.thirdItem.description'
                )}
                icon={cardIcon}
              />
              <Carditem
                title={i18next.t(
                  'sales.autoSubscriptionModal.body.fourthItem.title'
                )}
                description={i18next.t(
                  'sales.autoSubscriptionModal.body.fourthItem.description'
                )}
                icon={cancelIcon}
              />
            </div>

            <div className="mt-10">
              <Trans
                i18nKey="sales.autoSubscriptionModal.footer"
                components={{
                  a: (
                    <a
                      onClick={() => window.location.reload()}
                      className="text-sky-900 font-medium hover:cursor-pointer"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </div>
          </>
        }
        primaryBtnText={i18next.t('sales.autoSubscriptionModal.subscribeBtn')}
        primaryButtonIcon={{
          iconClass: 'icofont-external-link',
          direction: 'right',
        }}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickPrimaryBtn={() => window.open(userState.paymentLink, '_blank')}
        onClickSecondaryBtn={onCloseModal}
        onCloseModal={onCloseModal}
        width="max-w-3xl"
      />
    )
  )
}

const Carditem = ({title, description, icon}) => (
  <div>
    <div
      className={`group relative basis-1/3 border border-violet/[.1] p-5 rounded-md bg-gradient-to-r from-violet/[.05] to-ripeOrange/[.05] h-full`}>
      <div className="flex items-center mb-2">
        <img className="mr-3" src={icon} />
        <div className={`font-medium text-md ${linearGradientStyle}`}>
          {title}
        </div>
      </div>
      <div className="text-sm leading-6">{description}</div>
      <div
        className={`absolute right-[10px] bottom-[10px] opacity-[.06] transition ease-out duration-200 group-hover:opacity-[.1]`}>
        <img className="w-16" src={icon} />
      </div>
    </div>
  </div>
)

Carditem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
}
