import PropTypes from 'prop-types'
import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {statusList, severityList, issueCategoryList} from 'constant'
import Select from 'react-select'

import {useSelector} from 'react-redux'

const reactSelectStyles = {
  placeholder: provided => ({
    ...provided,
    color: '#ccd5e1',
  }),
}

const reactSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral20: '#e2e8f0',
  },
})

export function CloudFilter({column: {filterValue, setFilter}}) {
  function handleFilter(filter) {
    setFilter(filter)
  }

  const activeCloudIntegrationsState = useSelector(
    state => state.activeCloudIntegrations
  )

  const [groupedOptions, setGroupedOptions] = useState([])

  let awsOptions
  let azureOptions
  let gcpOptions

  useEffect(() => {
    if (activeCloudIntegrationsState) {
      awsOptions = activeCloudIntegrationsState.aws.map(awsIntegration => {
        return {
          value: awsIntegration.accountId,
          label: awsIntegration.nameWithAlias,
        }
      })

      azureOptions = activeCloudIntegrationsState.azure.map(
        azureIntegration => {
          return {
            value: azureIntegration.tenantId,
            label: azureIntegration.tenantId,
          }
        }
      )

      gcpOptions = activeCloudIntegrationsState.gcp.map(gcpIntegration => {
        return {
          value: gcpIntegration.projectId,
          label: gcpIntegration.projectId,
        }
      })

      setGroupedOptions([
        {
          label: `AWS (${i18next.t('cloud.accountId')})`,
          options: awsOptions,
        },
        {
          label: `Azure (${i18next.t('cloud.directoryId')})`,
          options: azureOptions,
        },
        {
          label: `GCP (${i18next.t('cloud.projectId')})`,
          options: gcpOptions,
        },
      ])
    }
  }, [activeCloudIntegrationsState])

  return (
    groupedOptions && (
      <div className="w-full text-xs inline-block">
        <Select
          isMulti
          placeholder={i18next.t('tables.filterByCloudIntegration')}
          styles={reactSelectStyles}
          options={groupedOptions}
          theme={reactSelectTheme}
          value={filterValue}
          onChange={item => {
            handleFilter(item)
          }}
        />
      </div>
    )
  )
}

export function SeverityFilter({column: {filterValue, setFilter}}) {
  function handleFilter(filter) {
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterBySeverity')}
        styles={reactSelectStyles}
        options={
          severityList &&
          Object.keys(severityList).map(key => ({
            value: key,
            label: severityList[key],
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function DomainFilter({column: {filterValue, setFilter}}) {
  const domains = useSelector(state => state.domainsHostnames)

  function handleFilter(filter) {
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterByDomain')}
        styles={reactSelectStyles}
        options={
          domains &&
          domains.map(domain => ({
            value: domain.id,
            label: domain.hostname,
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function AssignedToFilter({column: {filterValue, setFilter}}) {
  const companyMembersState = useSelector(state => state.companyMembers)

  function handleFilter(filter) {
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterByAssignee')}
        styles={reactSelectStyles}
        options={
          companyMembersState &&
          companyMembersState.map(member => ({
            value: member.id,
            label: member.firstName + ' ' + member.lastName,
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function StatusFilter({column: {filterValue, setFilter}}) {
  function handleFilter(filter) {
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterByStatus')}
        styles={reactSelectStyles}
        options={
          statusList &&
          Object.keys(statusList)
            .filter(key => {
              return key === 'new' ? false : true
            })
            .map(key => ({
              value: key,
              label: statusList[key],
            }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function AssignmentFilter(props) {
  const filterValue = props.column.filterValue
  const setFilter = props.column.setFilter

  const assignedToCol = props.columns.find(item => item.id === 'assignedTo')
  const assignedToFilter = assignedToCol.setFilter

  function handleFilter(filter) {
    assignedToFilter([])
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isClearable
        placeholder={i18next.t('tables.filterByAssignment')}
        styles={reactSelectStyles}
        options={[
          {value: 'assigned', label: i18next.t('finding.assigned')},
          {value: 'unassigned', label: i18next.t('finding.unassigned')},
        ]}
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function CategoryFilter(props) {
  const filterValue = props.column.filterValue
  const setFilter = props.column.setFilter

  const cloudCol = props.columns.find(item => item.id === 'cloudAccount')
  const cloudSetFilter = cloudCol.setFilter

  const handleCloudFilter = filter => {
    if (!filter.find(item => item.value === 'cloudAccount')) cloudSetFilter([])
  }

  function handleFilter(filter) {
    handleCloudFilter(filter)
    setFilter(filter)
  }

  return (
    <div className="w-full text-xs inline-block">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterByCategory')}
        styles={reactSelectStyles}
        options={
          issueCategoryList &&
          Object.keys(issueCategoryList).map(key => ({
            value: key,
            label: issueCategoryList[key],
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

AssignmentFilter.propTypes = {
  column: PropTypes.object,
  columns: PropTypes.array,
}
AssignedToFilter.propTypes = {column: PropTypes.object}
DomainFilter.propTypes = {column: PropTypes.object}
StatusFilter.propTypes = {column: PropTypes.object}
SeverityFilter.propTypes = {column: PropTypes.object}
CategoryFilter.propTypes = {
  column: PropTypes.object,
  columns: PropTypes.array,
  state: PropTypes.object,
}
CloudFilter.propTypes = {column: PropTypes.object}
