import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeSlug from 'rehype-slug'
import gfm from 'remark-gfm'

import {getBrowserLanguage, getMarkdownContent} from 'utils'

import images from 'res'

const PrivacyPolicy = () => {
  const browserLanguage = getBrowserLanguage()
  const privacyPolicyPath = `/legal/privacy-policy/${browserLanguage}/unxpose-privacy-policy.md`

  return (
    <div className="bg-astral-50">
      <header
        id="companies-page-header"
        className="py-4 md:py-6 pl-4 md:pl-10 bg-sky-900">
        <a href="/">
          <img src={images.WhiteLogo} alt="Unxpose" className="w-40" />
        </a>
      </header>
      <div className="w-fit template-markdown bg-white border rounded-lg p-4 md:p-8 md:m-10">
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeSlug]}
          components={{
            img: ({src, alt}) => {
              return (
                <img src={`res/legal/${browserLanguage}/${src}`} alt={alt} />
              )
            },
          }}>
          {getMarkdownContent(privacyPolicyPath)}
        </ReactMarkdown>
      </div>
    </div>
  )
}

export default PrivacyPolicy
