import AwesomeDebouncePromise from 'awesome-debounce-promise'
import i18next from 'i18next'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useConstant from 'use-constant'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from 'react-table'

import {
  getSeverityColor,
  getSeverityLabel,
  getSeverityString,
} from '../../utils/score'
import {
  formatFindingsFilterOptionsFromUrl,
  getFiltersFromUrl,
  getFilterFromUrl,
  getManagementFiltersMap,
  getPageFromUrl,
} from 'utils/filters'
import {
  AssignedToFilter,
  AssignmentFilter,
  CategoryFilter,
  CloudFilter,
  DomainFilter,
  SeverityFilter,
  StatusFilter,
} from './Filters'
import {
  defaultManagementFilters,
  statusList,
  tableStyles,
  findingAssetPaths,
} from 'constant'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import FilterMenu from './FilterMenu'
import Spinner from 'components/partials/Spinner'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {Trans} from 'react-i18next'
import FilterButton from 'components/partials/buttons/FilterButton'
import {fetchDomainsHostnames, loading} from 'actions'

export default function Table(props) {
  const companyMembersState = useSelector(state => state.companyMembers)
  const domainsState = useSelector(state => state.domainsHostnames)
  const loadingState = useSelector(state => state.loading)

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [dynamicHeight, setDynamicHeight] = useState('156')
  const [items, setItems] = useState([])

  const [showSelectItemsBtn, setShowSelectItemsBtn] = useState(true)
  const [showSelectAllAlert, setShowSelectAllAlert] = useState(false)
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [unselectedRowIds, setUnselectedRowIds] = useState([])

  const [filterMenuVisibility, setFilterMenuVisibility] = useState('hidden')

  const tableRef = useRef()

  const currentPageRowsIds = items.map(item => item.id)

  const handleDefaultFilters = location => {
    if (!location.search) return defaultManagementFilters

    const filters = ['assignment', 'category', 'severity', 'status']

    for (const filter of filters) {
      if (location.search.includes(filter)) return [{id: filter, value: []}]
    }

    return defaultManagementFilters
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        className: 'text-center',
        maxWidth: 5,
        Header: ({getToggleAllPageRowsSelectedProps}) => (
          <Checkbox
            name="selectAll"
            allRowsSelected={allRowsSelected}
            {...getToggleAllPageRowsSelectedProps()}
          />
        ),
        Cell: ({row}) => (
          <Checkbox
            name="selectRow"
            allRowsSelected={allRowsSelected}
            row={row}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: i18next.t('tables.title').toUpperCase(),
        accessor: 'title',
        disableFilters: true,
      },
      {
        Header: i18next.t('tables.asset').toUpperCase(),
        accessor: 'asset',
        width: 120,
        disableFilters: true,
      },
      {
        Header: i18next.t('tables.severity').toUpperCase(),
        accessor: 'severity',
        Filter: SeverityFilter,
        filter: rows => rows,
        width: 30,
      },
      {
        Header: i18next.t('tables.status').toUpperCase(),
        accessor: 'status',
        Filter: StatusFilter,
        filter: rows => rows,
        width: 30,
      },
      {
        Header: 'Assignment',
        accessor: 'assignment',
        hidden: true,
        Filter: AssignmentFilter,
        filter: rows => rows,
      },
      {
        Header: 'AssignedTo',
        accessor: 'assignedTo',
        hidden: true,
        Filter: AssignedToFilter,
        filter: rows => rows,
      },
      {
        Header: 'Domain',
        accessor: 'domainId',
        hidden: true,
        Filter: DomainFilter,
        filter: rows => rows,
      },
      {
        Header: 'Category',
        accessor: 'type',
        hidden: true,
        Filter: CategoryFilter,
        filter: rows => rows,
      },
      {
        Header: 'Cloud',
        accessor: 'cloudAccount',
        hidden: true,
        Filter: CloudFilter,
        filter: rows => rows,
      },
      {
        Header: i18next.t('tables.seen').toUpperCase(),
        disableFilters: true,
        accessor: 'checkedAt',
        width: 50,
      },
    ],
    [allRowsSelected]
  )

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
      Filter: () => '',
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    toggleHideColumn,
    headerGroups,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    preGlobalFilteredRows,
    previousPage,
    state: {pageIndex, sortBy, filters, globalFilter, selectedRowIds},
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
    toggleAllPageRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      getRowId: useCallback(row => row.id, []),
      columns,
      data: items,
      autoResetSelectedRows: allRowsSelected,
      defaultColumn,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: props.pageCount,
      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
        filters: getFiltersFromUrl(
          location,
          getManagementFiltersMap,
          companyMembersState,
          domainsState
        ).length
          ? getFiltersFromUrl(
              location,
              getManagementFiltersMap,
              companyMembersState,
              domainsState
            )
          : handleDefaultFilters(location),
        globalFilter: getFilterFromUrl('global', location),
      },
    },
    useGlobalFilter,
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  )

  const onChangeCheckbox = (e, name, row, allRowsSelected, onChange) => {
    onChange(e)

    if (name === 'selectAll') {
      setAllRowsSelected(false)
      setUnselectedRowIds([])

      if (e.target.checked) {
        setShowSelectAllAlert(true)
      } else {
        setShowSelectAllAlert(false)
        toggleAllPageRowsSelected(false)
      }
    } else if (name === 'selectRow') {
      if (e.target.checked) {
        setUnselectedRowIds(prev => prev.filter(id => id !== row.id))
      } else {
        setShowSelectAllAlert(false)

        if (allRowsSelected) {
          setUnselectedRowIds(prev => {
            if (prev && !prev.includes(row.id)) return [...prev, row.id]
          })

          toggleRowSelected(row.id, false)
        }
      }
    }
  }

  const Checkbox = forwardRef(function checkbox(
    {name, row, indeterminate, onChange, allRowsSelected, ...rest},
    ref
  ) {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <input
        type="checkbox"
        className="h-4 w-4 mt-1"
        ref={resolvedRef}
        onChange={e =>
          onChangeCheckbox(e, name, row, allRowsSelected, onChange)
        }
        {...rest}
      />
    )
  })

  function restorePreviousFilterOptions() {
    const filtersApplied = getFiltersFromUrl(
      location,
      getManagementFiltersMap,
      companyMembersState,
      domainsState
    )

    setAllFilters(filtersApplied)
  }

  function closeFilterMenu(e) {
    const filterMenu = document.querySelector('#filter-menu')
    const filterButton = document.querySelector('#filter-button')

    if (filterButton?.contains(e.target)) {
      return
    }

    if (!filterMenu.contains(e.target)) {
      setFilterMenuVisibility('hidden')
    }
  }

  function countAppliedFilters(headerGroups) {
    let count = 0
    const columnHeaderGroups = headerGroups[0]

    if (headerGroups) {
      columnHeaderGroups.headers.map(header => {
        if (!header.filterValue) return

        if (Array.isArray(header.filterValue)) {
          count = count + header.filterValue.length
        } else count++
      })
      return count
    }

    return 0
  }

  const toggleFilterMenu = menuVisibility => {
    if (menuVisibility === 'hidden') {
      if (!domainsState) {
        dispatch(loading({domainsHostnames: true}))
        dispatch(fetchDomainsHostnames())
      }

      setFilterMenuVisibility('block')
    } else setFilterMenuVisibility('hidden')
  }

  function hasFilterApplied() {
    if (!filters.length && !globalFilter) return false

    const hasFilterValue = filters.some(i => i.value && i.value.length)

    if (!hasFilterValue && !globalFilter) return false

    return true
  }

  function getFindingUrl(templateType, findingId) {
    if (templateType === 'people')
      return `/${templateType}/findings/${findingId}`
    return `/${templateType}/finding/${findingId}`
  }

  function updateUrlPagination(index) {
    if (!allRowsSelected) setShowSelectAllAlert(false)

    const prevUrl = new URLSearchParams(window.location.search)
    const uiPaginationIndex = index + 1 // plus one due to one-base indexing

    prevUrl.set('page', uiPaginationIndex)

    navigate(`?${prevUrl.toString()}`)
  }

  function goToUrlPagination() {
    const params = new URLSearchParams(location.search)
    const tablePaginationIndex = parseInt(params.get('page'), 10) - 1 // less one due to zero-base indexing

    gotoPage(tablePaginationIndex)
  }

  function updateUrlQueryStrings(sort, filters) {
    const prevUrl = new URLSearchParams(window.location.search)

    const sortQuery = sort.length
      ? sort.map(i => `${i.id}:${i.desc ? 'desc' : 'asc'}`).join(',')
      : ''
    prevUrl.set('sort', sortQuery)

    prevUrl.set('limit', props.pageSize)

    if (filters.length) {
      filters.forEach(i => {
        const id = i.id

        if (!i.value) {
          return prevUrl.set(id, '')
        }

        if (typeof i.value === 'string') {
          return prevUrl.set(id, i.value)
        }

        if (typeof i.value === 'object' && !Array.isArray(i.value)) {
          return prevUrl.set(id, i.value.value)
        }

        const values = i.value.map(i => i.value)
        prevUrl.set(id, values.join(','))
      })
    } else {
      prevUrl.set('status', '')
      prevUrl.set('severity', '')
      prevUrl.set('type', '')
      prevUrl.set('cloudAccount', '')
      prevUrl.set('domainId', '')
      prevUrl.set('assignment', '')
      prevUrl.set('assignedTo', '')
    }

    if (`?${prevUrl.toString()}` !== location.search)
      navigate(`?${prevUrl.toString()}`)
  }

  function updateGlobalFilter(filter) {
    const prevUrl = new URLSearchParams(window.location.search)
    if (!filter) filter = ''

    prevUrl.set('global', filter)
    navigate(`?${prevUrl.toString()}`)
  }

  const handlePrevious = pageIndex => {
    setIsLoading(true)

    props.fetchData({
      filterValue: getFilterFromUrl('global', location),
      filterOptions: formatFindingsFilterOptionsFromUrl(
        window.location,
        companyMembersState,
        domainsState
      ),
      pageIndex,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })

    updateUrlPagination(pageIndex)
    previousPage()
  }

  const handleNext = pageIndex => {
    setIsLoading(true)

    props.fetchData({
      filterValue: getFilterFromUrl('global', location),
      filterOptions: formatFindingsFilterOptionsFromUrl(
        window.location,
        companyMembersState,
        domainsState
      ),
      pageIndex,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })

    updateUrlPagination(pageIndex)
    nextPage()
  }

  const refreshTableData = sortBy => {
    if (!allRowsSelected) setShowSelectAllAlert(false)

    props.fetchData({
      pageSize: props.pageSize,
      filterValue: getFilterFromUrl('global', window.location),
      filterOptions: formatFindingsFilterOptionsFromUrl(
        window.location,
        companyMembersState,
        domainsState
      ),
      sortBy: sortBy,
      page: getPageFromUrl(window.location),
    })
  }

  const debouncedRefreshTable = useConstant(() => {
    return AwesomeDebouncePromise(() => refreshTableData(sortBy), 1000)
  })

  useEffect(() => {
    setIsLoading(false)
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (tableRef && tableRef.current) {
      setDynamicHeight(tableRef.current.clientHeight)
    }

    if (allRowsSelected) {
      currentPageRowsIds.forEach(id => {
        if (unselectedRowIds && !unselectedRowIds.includes(id))
          toggleRowSelected(id, true)
      })
    }
  }, [items])

  useEffect(
    () => toggleHideColumn('selection', showSelectItemsBtn),
    [showSelectItemsBtn]
  )

  useEffect(() => {
    if (unselectedRowIds?.length === items.data?.length) {
      setAllRowsSelected(false)
      setUnselectedRowIds([])
    }
  }, [unselectedRowIds])

  useEffect(() => {
    if (!firstPageLoad) {
      updateGlobalFilter(props.filterValue)
      updateUrlPagination(0)
      updateUrlQueryStrings(sortBy, filters)
    }
  }, [props.filterValue, props.pageSize])

  useEffect(() => {
    refreshTableData(sortBy)
  }, [props.pageSize])

  useEffect(() => {
    updateUrlQueryStrings(sortBy, filters)

    if (!firstPageLoad) refreshTableData(sortBy)
  }, [sortBy])

  useEffect(() => {
    goToUrlPagination()
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', closeFilterMenu)
    return () => document.removeEventListener('mouseup', closeFilterMenu)
  }, [])

  useEffect(() => {
    if (!firstPageLoad) debouncedRefreshTable()
  }, [debouncedRefreshTable, props.filterValue])

  useEffect(() => {
    setFirstPageLoad(false)
  }, [])

  useEffect(() => {
    if (!firstPageLoad) restorePreviousFilterOptions()
  }, [filterMenuVisibility])

  const isSomeRowSelected =
    Object.keys(selectedRowIds).length > 0 || allRowsSelected

  const filtersCount = countAppliedFilters(headerGroups)

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex mb-4">
          <div className="flex items-center justify-between relative w-full">
            <div id="left-side">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                value={globalFilter}
                rows={rows}
                setFilterValue={props.setFilterValue}
                setGlobalFilter={setGlobalFilter}
                setLoading={setIsLoading}
                gotoPage={gotoPage}
                customPlaceholder={i18next.t('tables.filterByTitleOrAsset')}
              />
            </div>

            <div id="right-side" className=" flex items-center">
              <div id="filter-container" className="relative">
                <FilterButton
                  filtersCount={filtersCount}
                  onClick={() => toggleFilterMenu(filterMenuVisibility)}
                />
                {headerGroups && (
                  <FilterMenu
                    filterMenuVisibility={filterMenuVisibility}
                    setFilterMenuVisibility={setFilterMenuVisibility}
                    headerGroups={headerGroups}
                    setAllFilters={setAllFilters}
                    sortBy={sortBy}
                    filters={filters}
                    setLoading={setIsLoading}
                    isLoadingFilters={loadingState.domainsHostnames}
                    updateUrlPagination={updateUrlPagination}
                    updateUrlQueryStrings={updateUrlQueryStrings}
                    refreshTableData={refreshTableData}
                    gotoPage={gotoPage}
                  />
                )}
              </div>

              <div id="select-btn-container" className="ml-4">
                {isSomeRowSelected ? (
                  <PrimaryButton
                    text={i18next.t('management.bulkChange.bulkChangeBtn')}
                    size="sm"
                    onClick={() =>
                      navigate('/management/bulkChange', {
                        state: {
                          allRowsSelected,
                          selectedRowIds: Object.keys(selectedRowIds),
                          unselectedRowIds,
                          findingsCount: props.total,
                          globalFilter: getFilterFromUrl(
                            'global',
                            window.location
                          ),
                          filterApplied: formatFindingsFilterOptionsFromUrl(
                            window.location,
                            companyMembersState,
                            domainsState
                          ),
                        },
                      })
                    }
                  />
                ) : (
                  <PrimaryButton
                    text={
                      showSelectItemsBtn
                        ? i18next.t('management.bulkChange.selectionItemsBtn')
                        : i18next.t('management.bulkChange.hideSelectionBtn')
                    }
                    theme="blue-outline"
                    size="sm"
                    onClick={() => setShowSelectItemsBtn(!showSelectItemsBtn)}
                  />
                )}
              </div>

              <div className="ml-4">
                <Pagination
                  handlePrevious={() => handlePrevious(pageIndex - 1)}
                  handleNext={() => handleNext(pageIndex + 1)}
                  canPreviousPage={!isLoading && canPreviousPage}
                  canNextPage={!isLoading && canNextPage}
                  paginationClass={props.paginationClass}
                  pageIndex={pageIndex}
                  pageCount={props.pageCount}
                  total={props.total}
                />
              </div>
            </div>
          </div>
        </div>
        {showSelectAllAlert && (
          <div className="bg-orange-200 border border-orange-300 text-sm text-center px-6 py-4 my-4">
            {allRowsSelected ? (
              <>
                <span className="mr-2">
                  <Trans
                    i18nKey="management.bulkChange.allFindingsSelected"
                    values={{
                      qty: props.total,
                    }}
                  />
                </span>
                <SecondaryButton
                  text={i18next.t('management.bulkChange.uncheckAll')}
                  size="sm"
                  classContainer="inline"
                  onClick={() => {
                    setShowSelectAllAlert(false)
                    setAllRowsSelected(false)

                    toggleAllRowsSelected(false)
                  }}
                />
              </>
            ) : (
              <>
                <span className="mr-2">
                  <Trans
                    i18nKey="management.bulkChange.allFindingsPageSelected"
                    values={{
                      qty: items.length,
                    }}
                  />
                </span>

                {items.length < props.total && (
                  <SecondaryButton
                    text={i18next.t(
                      'management.bulkChange.selectAllFindingsPage',
                      {
                        qty: props.total,
                      }
                    )}
                    size="sm"
                    classContainer="inline"
                    onClick={() => setAllRowsSelected(true)}
                  />
                )}
              </>
            )}
          </div>
        )}

        {page && page.length && !isLoading ? (
          <>
            <table ref={tableRef} {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2 py-3">
                      {headerGroup.headers.map(column => {
                        if (column.hidden) return <th key={uuidv4()}></th>

                        return (
                          <th
                            className={`${column.className} ${tableStyles.tableHeader}`}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={uuidv4()}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ↓'
                                  : ' ↑'
                                : ''}
                            </span>
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`${tableStyles.tableRow} px-2`}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (cell.column.hidden) return <td key={uuidv4()}></td>

                        if (cell.column.id === 'selection')
                          return (
                            <td key={uuidv4()} {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )

                        if (cell.column.id === 'asset') {
                          if (
                            !findingAssetPaths[cell.row.original.type] ||
                            !cell.row.original.assetId
                          ) {
                            return (
                              <td
                                className={`break-all ${tableStyles.tableData} truncate`}
                                {...cell.getCellProps()}
                                key={uuidv4()}>
                                {cell.render('Cell')}
                              </td>
                            )
                          }

                          return (
                            <td
                              className={`break-all ${tableStyles.tableData} truncate`}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`${
                                  findingAssetPaths[cell.row.original.type]
                                }/${cell.row.original.assetId}`}>
                                {cell.render('Cell')}
                              </Link>
                            </td>
                          )
                        }

                        if (cell.column.id === 'status')
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {statusList[cell.value]}
                            </td>
                          )

                        if (
                          cell.column.id === 'title' &&
                          cell.row.original.templateId?.startsWith('AZURE')
                        )
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`/azure/finding/${cell.row.original.id}`}
                                className="flex items-center">
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.original.severity
                                  )} severity-indicator flex-shrink-0 mr-2`}></span>
                                <p className="truncate">
                                  {cell.render('Cell')}
                                </p>
                              </Link>
                            </td>
                          )

                        if (
                          cell.column.id === 'title' &&
                          cell.row.original.templateId?.startsWith('GCP')
                        )
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`/gcp/finding/${cell.row.original.id}`}
                                className="flex items-center">
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.original.severity
                                  )} severity-indicator flex-shrink-0 mr-2`}></span>
                                <p className="truncate">
                                  {cell.render('Cell')}
                                </p>
                              </Link>
                            </td>
                          )

                        if (
                          cell.column.id === 'title' &&
                          cell.row.original.templateId?.startsWith('AWS')
                        )
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`/aws/finding/${cell.row.original.id}`}
                                className="flex items-center">
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.original.severity
                                  )} severity-indicator flex-shrink-0 mr-2`}></span>
                                <p className="truncate">
                                  {cell.render('Cell')}
                                </p>
                              </Link>
                            </td>
                          )

                        if (cell.column.id === 'title')
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={getFindingUrl(
                                  cell.row.original.type,
                                  cell.row.original.id
                                )}
                                className="flex items-center">
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.original.severity
                                  )} severity-indicator flex-shrink-0 mr-2`}></span>
                                <p className="truncate">
                                  {cell.render('Cell')}
                                </p>
                              </Link>
                            </td>
                          )

                        if (
                          cell.column.id === 'severity' &&
                          typeof cell.value === 'number'
                        )
                          return (
                            <td
                              className={tableStyles.tableData + ' capitalize'}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {getSeverityLabel(getSeverityString(cell.value))}
                            </td>
                          )

                        if (
                          cell.column.id === 'bestDate' ||
                          cell.column.id === 'checkedAt' ||
                          cell.column.id === 'createdAt' ||
                          cell.column.id === 'updatedAt'
                        ) {
                          const moment =
                            cell.row.original.checkedAt ||
                            cell.row.original.updatedAt ||
                            cell.row.original.createdAt
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className={tableStyles.tableData}>
                              <Moment date={new Date(moment)} fromNow />
                            </td>
                          )
                        }

                        return (
                          <td
                            className={
                              cell.column.classes
                                ? cell.column.classes
                                : 'py-2 pr-2 text-center truncate break-word'
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? cell.render('Cell') : '—'}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            {isLoading ? (
              <div
                className="flex items-center justify-center"
                style={{height: `${dynamicHeight}px`}}>
                <Spinner />
              </div>
            ) : hasFilterApplied() ? (
              props.emptyStateWithFilter
            ) : (
              props.emptyStateWithoutFilter
            )}
          </>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  fetchData: PropTypes.func,
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  defaultSorted: PropTypes.array,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  total: PropTypes.number,
  pageCount: PropTypes.number,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  indeterminate: PropTypes.object,
  row: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  allRowsSelected: PropTypes.object,
}
