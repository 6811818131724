import {handler} from './handler'
import {
  authenticateDone,
  fetchCompany,
  fetchCsrfDone,
  fetchNextStepsDone,
  fetchUser,
  fetchUserDone,
  generate2faDone,
  genericError,
  logout,
  logoutDone,
  registerDone,
  reload,
  success,
  updateNotificationSettingsDone,
  verifySessionDone,
} from 'actions'

import config from '../config'
import i18next from 'i18next'

export function* handleAuthenticate(action) {
  yield handler({
    handler: authenticate,
    success: authenticateDone,
    error: res => {
      let message

      switch (res.code) {
        case 'INVALID_CREDENTIALS':
          message = i18next.t('errors.invalidCredentials')
          break

        case 'SUBSCRIPTION_EXPIRED':
          message = i18next.t('errors.planExpired')
          break

        case 'NOT_READY':
          message = i18next.t('auth.errors.notReady')
          break

        default:
          message = i18next.t('errors.unexpectedError')
          break
      }

      return genericError({loading: {login: false}, message})
    },
    action,
  })
}

const authenticate = function (body) {
  return fetch(config.SERVER_URL + '/token', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleSignUp(action) {
  yield handler({
    handler: signUp,
    success: () =>
      success({
        loading: {signUp: false},
        message: i18next.t('signUp.messages.success'),
      }),
    error: res => {
      let message

      switch (res.code) {
        case 'INVALID_EMAIL':
          message = i18next.t('signUp.messages.invalidEmail')
          break

        case 'TOO_MANY_REQUESTS':
          message = i18next.t('signUp.messages.tooManyRequests')
          break

        default:
          message = i18next.t('errors.unexpectedError')
          break
      }

      return genericError({loading: {signUp: false}, message})
    },
    action,
  })
}

const signUp = function (body) {
  const url = `${config.SERVER_URL}/signup${location.search}`

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRegister(action) {
  yield handler({
    handler: register,
    error: res => {
      let message

      switch (res.code) {
        case 'SHORT_PASSWORD':
          message = i18next.t('auth.errors.shortPassword')
          break

        case 'USER_ALREADY_REGISTERED':
          message = i18next.t('settings.errors.userAlreadyRegistered', {
            email: res.email,
          })
          break
        case 'NOT_READY':
          message = i18next.t('auth.errors.notReady')
          break
        default:
          message = i18next.t('errors.unexpectedError')
          break
      }

      return genericError({loading: {register: false}, message})
    },
    success: [
      () =>
        success({
          loading: {register: false},
          message: i18next.t('signUp.register.messages.success'),
        }),
      registerDone,
    ],
    action,
  })
}

const register = function (body) {
  var path = body.id ? '/register/' + body.id : '/register'
  return fetch(config.SERVER_URL + path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleLogout(action) {
  yield handler({
    handler: body =>
      fetch(config.SERVER_URL + '/logout', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
      }).catch(err => {
        console.trace(err)
      }),
    success: logoutDone,
    error: logoutDone,
    action,
  })
}

export function* handleFetchUser() {
  yield handler({
    handler: () =>
      fetch(config.SERVER_URL + '/user', {
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        mode: 'cors',
      }).catch(err => {
        console.trace(err)
      }),
    success: fetchUserDone,
    error: logout,
  })
}

export function* handleSaveProfile(action) {
  const body = {...action.payload.user, _csrf: action.payload._csrf}
  yield handler({
    handler: () => saveProfile(body),
    error: genericError,
    success: action.payload.shouldReload
      ? () => reload(action.payload.user)
      : [fetchUser, success],
    action,
  })
}

const saveProfile = function (body) {
  return fetch(config.SERVER_URL + '/user', {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdatePassword(action) {
  yield handler({
    handler: updatePassword,
    error: () =>
      genericError({message: i18next.t('auth.errors.incorrectPassword')}),
    success: () =>
      success({message: i18next.t('auth.success.passwordUpdated')}),
    action,
  })
}

const updatePassword = function (body) {
  return fetch(config.SERVER_URL + '/password', {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleDisconnectGoogle(action) {
  yield handler({
    handler: disconnectGoogle,
    error: genericError,
    success: () =>
      (window.location.href = '/settings/profile?message=googleDisconnected'),
    action,
  })
}

const disconnectGoogle = function (body) {
  return fetch(config.SERVER_URL + '/auth/google/disconnect', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleDisconnectMicrosoft(action) {
  yield handler({
    handler: disconnectMicrosoft,
    error: genericError,
    success: () =>
      (window.location.href =
        '/settings/profile?message=microsoftDisconnected'),
    action,
  })
}

const disconnectMicrosoft = function (body) {
  return fetch(config.SERVER_URL + '/auth/microsoft/disconnect', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleForgotPassword(action) {
  yield handler({
    handler: forgotPassword,
    error: () =>
      genericError({
        loading: {forgotPassword: false},
        message: i18next.t('errors.invalidEmailAddress'),
      }),
    success: () =>
      success({
        loading: {forgotPassword: false},
        message: i18next.t('auth.success.checkYourMailBox'),
      }),
    action,
  })
}

const forgotPassword = function (body) {
  return fetch(`${config.SERVER_URL}/forgot`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleResetPassword(action) {
  yield handler({
    handler: resetPassword,
    error: () =>
      genericError({
        loading: {resetPassword: false},
        message: i18next.t('errors.invalidOrExpiredLink'),
      }),
    success,
    action,
  })
}

const resetPassword = function (body) {
  return fetch(`${config.SERVER_URL}/password/reset/${body.id}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleCompleteGuide(action) {
  yield handler({
    handler: completeGuide,
    success: [fetchUser],
    action,
  })
}

const completeGuide = function (body) {
  return fetch(`${config.SERVER_URL}/guides/${body.endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleGenerate2fa(action) {
  yield handler({
    handler: generate2fa,
    success: generate2faDone,
    action,
  })
}

const generate2fa = function (body) {
  return fetch(config.SERVER_URL + '/user/generate2fa', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    mode: 'cors',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleEnable2fa(action) {
  yield handler({
    handler: enable2fa,
    error: genericError,
    success: [success, fetchUser],
    action,
  })
}

const enable2fa = function (body) {
  return fetch(config.SERVER_URL + '/user/enable2fa', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleDisable2fa(action) {
  yield handler({
    handler: disable2fa,
    error: genericError,
    success: [success, fetchUser],
    action,
  })
}

const disable2fa = function (body) {
  return fetch(config.SERVER_URL + '/user/disable2fa', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleCheck2fa(action) {
  yield handler({
    handler: check2fa,
    error: res => {
      let message

      switch (res.code) {
        case 'EXPIRED_CODE':
          message = i18next.t('errors.expiredCode')
          break
        case 'INVALID_CODE':
          message = i18next.t('errors.invalidCode')
          break
        case 'INVALID_CREDENTIALS':
          message = i18next.t('errors.invalidCredentials')
          break
        default:
          message = i18next.t('errors.somethingWentWrong')
      }
      return genericError({message, loading: {login: false}})
    },
    success: authenticateDone,
    action,
  })
}

const check2fa = function (body) {
  return fetch(config.SERVER_URL + '/check2fa', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleVerifySession(action) {
  yield handler({
    handler: verifySession,
    error: genericError,
    success: verifySessionDone,
    action,
  })
}

const verifySession = function (sessionId) {
  return fetch(`${config.SERVER_URL}/session/${sessionId}`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateNotificationSettings(action) {
  yield handler({
    handler: updateNotificationSettings,
    error: genericError,
    success: updateNotificationSettingsDone,
    action,
  })
}

const updateNotificationSettings = notifications => {
  return fetch(config.SERVER_URL + '/notifications', {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(notifications),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateUserRole(action) {
  yield handler({
    handler: updateUserRole,
    error: genericError,
    success: [success, fetchCompany],
    action,
  })
}

const updateUserRole = body => {
  return fetch(`${config.SERVER_URL}/users/${body.userId}/role`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchCsrf(action) {
  yield handler({
    handler: fetchCSRF,
    success: fetchCsrfDone,
    error: genericError,
    action,
  })
}

const fetchCSRF = () => {
  return fetch(config.SERVER_URL + '/csrf', {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNextSteps(action) {
  yield handler({
    handler: _fetchNextSteps,
    error: genericError,
    success: fetchNextStepsDone,
    action,
  })
}

const _fetchNextSteps = () => {
  const url = new URL(`${config.SERVER_URL}/next-steps`)

  return fetch(url, {
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
