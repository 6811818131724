import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {companyRenewal} from 'actions'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PaperForm from 'components/partials/PaperForm'

export default function UpgradePlanModal({setModal}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [showPaperform, setShowPaperform] = useState(false)

  const onCloseModal = () => {
    setModal(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const planRenewalModal = document.querySelector('#upgradePlanModal')
      if (e.target === planRenewalModal) onCloseModal()
    })
  }, [])

  return (
    <div
      id="upgradePlanModal"
      className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
      <div className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <div className="relative">
          {showPaperform ? (
            <PaperForm
              classes="relative"
              title={i18next.t(
                'settings.subscription.upgradeModal.paperformTitle'
              )}
              onClickBack={() => setShowPaperform(false)}
            />
          ) : (
            <>
              <div id="modal-header" className="text-center">
                <div id="icon" className="inline-block m-auto">
                  <i className="icofont-thunder-light text-5xl text-[#FFB743]" />
                </div>
                <div
                  id="title"
                  className="font-medium text-sideral-90 text-lg mb-6 mt-2">
                  {i18next.t('settings.subscription.upgradeModal.title')}
                </div>
              </div>
              <div id="modal-body">
                <div className="description text-sm text-gray-600 leading-6 mb-6">
                  {i18next.t('settings.subscription.upgradeModal.body')}
                </div>
              </div>
              <div id="modal-footer" className="text-center">
                <div className="w-full">
                  <PrimaryButton
                    text={i18next.t(
                      'settings.subscription.upgradeModal.primaryBtn'
                    )}
                    type="submit"
                    size="full"
                    margin="mb-2"
                    onClick={() => {
                      setShowPaperform(true)
                      dispatch(
                        companyRenewal({
                          _csrf: csrfState,
                          companyId: userState.company.id,
                          element: 'talkToSales',
                          source: 'settingsUpgradeModal',
                        })
                      )
                    }}
                  />
                </div>

                <div className="w-full">
                  <SecondaryButton
                    text={i18next.t(
                      'settings.subscription.upgradeModal.secondaryBtn'
                    )}
                    onClick={onCloseModal}
                    size="sm"
                  />
                </div>
              </div>
            </>
          )}

          <button className="absolute top-0 right-0" onClick={onCloseModal}>
            <i className="icofont-close text-xl" />
          </button>
        </div>
      </div>
    </div>
  )
}

UpgradePlanModal.propTypes = {
  setModal: PropTypes.func,
}
