import PropTypes from 'prop-types'
import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  fetchActiveCloudIntegrations,
  fetchCompanyMembers,
  fetchAllFindings,
  fetchAllFindingsCount,
  toggleRestrictionModal,
  fetchDomainsHostnames,
} from 'actions'

import {
  formatFindingsFilterOptionsFromUrl,
  getFilterFromUrl,
  getPageFromUrl,
  getSortFromUrl,
} from 'utils/filters'

import PlanUtils from 'utils/plan'

import config from 'config'
import {
  sideral300,
  sky500,
  defaultManagementFilters,
  paginationOptions,
} from 'constant'
import images from 'res'
import Table from './Table'
import Spinner from '../partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import GenericEmptyState from 'components/partials/GenericEmptyState'

import {getRequestParams} from 'utils/pagination'
import {Page} from 'components/partials/Page'
import FindingsEmptyState from './FindingsEmptyState'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import AccessibilityAlert from 'components/partials/AccessibilityAlert'

export default function Mangement() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)
  const companyMembersState = useSelector(state => state.companyMembers)
  const domainsState = useSelector(state => state.domainsHostnames)
  const allFindingsState = useSelector(state => state.allFindings)
  const allFindingsCountState = useSelector(state => state.allFindingsCount)
  const activeCloudIntegrationsState = useSelector(
    state => state.activeCloudIntegrations
  )

  const [filterValue, setFilterValue] = useState('')
  const [shouldFetchDomainsHostnames, setShouldFetchDomainsHostnames] =
    useState(false)
  const [pageSize, setPageSize] = useState(
    parseInt(getFilterFromUrl('limit', location), 10) || 10
  )

  const pageIndex = 0

  const planUtils = PlanUtils()

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const url = new URL(`${config.SERVER_URL}/findings`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        pageIndex: 0,
        filterValue: filterValue,
        filterOptions: {
          ...formatFindingsFilterOptionsFromUrl(
            location,
            companyMembersState,
            domainsState
          ),
          page: 0,
        },
      })
    ).toString()

    window.location.href = url
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('status') === null) {
      navigate({
        pathname: '/management',
        search: `?status=${defaultManagementFilters
          .find(f => f.id === 'status')
          .value.map(f => f.value)
          .join(',')}`,
      })
    }

    if (params.get('domainId') && !domainsState) {
      setShouldFetchDomainsHostnames(true)
    }
  }, [])

  useEffect(() => {
    if (!allFindingsCountState) dispatch(fetchAllFindingsCount())

    if (!planUtils.hasPlanRestriction()) {
      dispatch(
        fetchAllFindings({
          filterValue: getFilterFromUrl('global', location),
          page: getPageFromUrl(location),
          limit: pageSize,
          filterOptions: formatFindingsFilterOptionsFromUrl(
            window.location,
            companyMembersState,
            domainsState
          ),
          sortBy: getSortFromUrl(location, 'severity:desc'),
        })
      )

      if (!activeCloudIntegrationsState)
        dispatch(fetchActiveCloudIntegrations())
      if (!companyMembersState) dispatch(fetchCompanyMembers())
    }
  }, [])

  useEffect(() => {
    if (shouldFetchDomainsHostnames) dispatch(fetchDomainsHostnames())
  }, [shouldFetchDomainsHostnames])

  return (
    <Page pageTitle={i18next.t('pageTitles.allFindings')}>
      {/* FIRST SECTION */}

      {allFindingsCountState ? (
        <FindingsStats
          closed={allFindingsCountState.closed}
          none={allFindingsCountState.open}
          fixed={allFindingsCountState.fixed}
          riskAccepted={allFindingsCountState.riskAccepted}
          falsePositives={allFindingsCountState.falsePositives}
          stale={allFindingsCountState.stale}
        />
      ) : (
        <FindingsStats
          closed={0}
          none={0}
          fixed={0}
          riskAccepted={0}
          falsePositives={0}
          stale={0}
        />
      )}

      {planUtils.hasPlanRestriction() ? (
        <div className="mt-8">
          <FindingsEmptyState />
        </div>
      ) : allFindingsState ? (
        <>
          <TableWrapper
            title={`${i18next.t('titles.allFindingsFor')} ${
              userState?.company?.name
            }`}
            button={
              <button
                className="ml-auto"
                onClick={handleGenerateCSV}
                title={i18next.t('buttons.downloadCSV')}>
                <images.DownloadIcon height="24" width="24" color={sky500} />
              </button>
            }
            loaded={allFindingsState && companyMembersState}
            margin="mt-10"
            customBorder="border border-b-0 rounded-lg rounded-b-none"
            table={
              <Table
                fetchData={props =>
                  dispatch(
                    fetchAllFindings({
                      ...props,
                      limit: pageSize,
                    })
                  )
                }
                defaultSorted={getSortFromUrl(location, 'severity:desc')}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                total={allFindingsState.total}
                data={allFindingsState.data}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={allFindingsState.pages}
                emptyStateWithFilter={
                  <GenericEmptyState
                    icon={
                      <images.ManagementIcon width="55" color={sideral300} />
                    }
                    title={i18next.t('tables.nothingFoundWithSearch')}
                    body={i18next.t('tables.searchSomethingElse')}
                    margin={'mt-6 mb-2'}
                  />
                }
                emptyStateWithoutFilter={
                  <GenericEmptyState
                    icon={
                      <images.ManagementIcon width="55" color={sideral300} />
                    }
                    title={i18next.t('titles.goodNews')}
                    body={i18next.t('compliance.lgpd.success.noFindings')}
                    margin={'mt-6 mb-2'}
                  />
                }
              />
            }
          />
          <div className="flex justify-end bg-astral-50 border border-sideral-100 rounded-b-lg text-sm p-3">
            <PrimarySelect
              name="paginationOptions"
              value={{
                value: pageSize,
                label: i18next.t('misc.paginationItems', {qty: pageSize}),
              }}
              options={paginationOptions}
              padding={1}
              marginBottom="0"
              className="w-32"
              onChange={e => setPageSize(e.value)}
              isSearchable={false}
              menuPlacement="top"
            />
          </div>
        </>
      ) : (
        <>
          <Spinner />
        </>
      )}

      <AccessibilityAlert />
    </Page>
  )
}

const ValueAndToolTip = obj => {
  const {count} = obj
  const shouldDisplayTooltip = count > 999

  const formatNumber = number =>
    Intl.NumberFormat('en', {notation: 'compact'}).format(number)

  return (
    <div
      className={`flex ${
        shouldDisplayTooltip ? 'tooltip' : ''
      } mb-px relative align-center`}>
      <div className="relative">
        {formatNumber(count)}
        {shouldDisplayTooltip && (
          <span className="tooltip-text text-xs text-white rounded bg-sideral-300 p-3 absolute top-0 left-full ml-2">
            {count}
          </span>
        )}
      </div>
    </div>
  )
}

const FindingsStats = ({
  closed,
  none,
  fixed,
  riskAccepted,
  falsePositives,
  stale,
}) => {
  const responsiveContainer = 'flex w-full lg:w-4/12'
  const containerStyle =
    'flex flex-wrap rounded-lg border-2 border-ink bg-white w-full p-4'
  const innerContainerStyle =
    'relative bg-white rounded-sm flex-1 text-left mr-2 mt-2 pr-0 xl:pr-0 lg:pr-8 lg:w-28'
  const dataStyle = 'text-sideral-900 w-full text-3xl font-medium'
  const labelStyle =
    'uppercase text-sideral-300 text-base lg:text-sm xl:text-base font-light'
  const customBorderStyle =
    'absolute right-0 bottom-1/2 transform translate-y-1/2 w-2px h-16 bg-sideral-50 mr-6'

  return (
    <div className="lg:flex">
      <div className={containerStyle}>
        <div className={responsiveContainer}>
          <div className={innerContainerStyle}>
            <span className={dataStyle}>
              <ValueAndToolTip count={none} />
            </span>

            <h2 className={labelStyle}>{i18next.t('status.plural.open')}</h2>
            <span className={customBorderStyle} />
          </div>

          <div className={innerContainerStyle}>
            <span className={dataStyle}>
              <ValueAndToolTip count={fixed} />
            </span>
            <h2 className={labelStyle}>{i18next.t('status.plural.fixed')}</h2>
            <span className={`${customBorderStyle} hidden lg:block`} />
          </div>
        </div>
        <div className={responsiveContainer}>
          <div className={innerContainerStyle}>
            <span className={dataStyle}>
              <ValueAndToolTip count={riskAccepted} />
            </span>

            <h2 className={labelStyle}>
              {i18next.t('status.plural.riskAccepted')}
            </h2>
            <span className={customBorderStyle} />
          </div>
          <div className={innerContainerStyle}>
            <span className={dataStyle}>
              <ValueAndToolTip count={falsePositives} />
            </span>
            <h2 className={labelStyle}>
              {i18next.t('status.plural.falsePositive')}
            </h2>
            <span className={`${customBorderStyle} hidden lg:block`} />
          </div>
        </div>
        <div className={responsiveContainer}>
          <div className={innerContainerStyle}>
            <span className={dataStyle}>
              <ValueAndToolTip count={stale} />
            </span>
            <h2 className={labelStyle}>{i18next.t('status.plural.stale')}</h2>
            <span className={customBorderStyle} />
          </div>
          <div className={innerContainerStyle + ' lg:w-24'}>
            <span className={dataStyle}>
              <ValueAndToolTip count={closed} />
            </span>
            <h2 className={labelStyle}>{i18next.t('status.plural.closed')}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

FindingsStats.propTypes = {
  fixed: PropTypes.number,
  closed: PropTypes.number,
  stale: PropTypes.number,
  riskAccepted: PropTypes.number,
  falsePositives: PropTypes.number,
  none: PropTypes.number,
}
