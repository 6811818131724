import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {addDomain, loading} from 'actions'
import {isLoading} from 'utils'
import {DefaultModal} from 'components/partials/DefaultModal'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import {Trans} from 'react-i18next'
import images from 'res'
import {domainVerificationMethods, sky900} from 'constant'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'

const VerifyDomainModal = ({modal, setModal}) => {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)
  const successState = useSelector(state => state.success)

  const [copiedMsgOpacity, setCopiedMsgOpacity] = useState('opacity-0')

  const {assetVerificationId} = userState.company
  const assetVerificationString = `unxpose-verification=${assetVerificationId}`

  const onClickVerifyDomain = () => {
    dispatch(loading({addDomain: true}))
    dispatch(
      addDomain({
        hostname: modal.hostName,
        verificationMethod: modal.method,
        _csrf: csrfState,
      })
    )
  }

  const donwloadTxtFile = () => {
    const blob = new Blob([`${assetVerificationId}`], {
      type: 'text/plain',
    })

    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.download = `${assetVerificationId}.txt`
    link.href = url

    link.click()
  }

  useEffect(() => {
    if (successState && !isLoading(loadingState.addDomain)) {
      setModal({showModal: false})
    }
  }, [loadingState.addDomain])

  return (
    <DefaultModal
      id="domainVerificationModal"
      title={i18next.t('dns.domainVerification.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <div>
            <Trans
              i18nKey="dns.domainVerification.body.firstParagraph"
              values={{
                hostName: modal.hostName,
              }}
            />
          </div>

          <div className="mt-2 mb-4">
            <Trans i18nKey="dns.domainVerification.body.secondParagraph" />
          </div>

          <PrimarySelect
            name="verificationMethod"
            text={i18next.t('dns.domainVerification.selectMethod.label')}
            options={domainVerificationMethods}
            onChange={e =>
              setModal({
                ...modal,
                method: e.value,
              })
            }
            isSearchable={false}
          />

          {modal.method === 'record' && (
            <DomainVerificationSteps
              steps={[
                {
                  description: (
                    <Trans i18nKey="dns.domainVerification.recordMethodSteps.first" />
                  ),
                },
                {
                  description: (
                    <Trans i18nKey="dns.domainVerification.recordMethodSteps.second" />
                  ),
                  note: (
                    <div className="relative flex items-center">
                      <div
                        className={`absolute -top-[1.2rem] bg-teal-700 text-white rounded px-2 transition-all duration-150 ease-out ${copiedMsgOpacity}`}>
                        {i18next.t('misc.copiedToClipboard')}
                      </div>

                      <SecondaryButton
                        icon={
                          <images.copyIcon
                            width="18"
                            height="18"
                            color={sky900}
                          />
                        }
                        onClick={() => {
                          navigator.clipboard.writeText(assetVerificationString)

                          setCopiedMsgOpacity('opacity-1 -translate-y-1/3')
                          setTimeout(() => {
                            setCopiedMsgOpacity('opacity-0')
                          }, 2000)
                        }}
                        size="sm"
                        classContainer="inline-flex"
                      />

                      <div>{assetVerificationString}</div>
                    </div>
                  ),
                },
                {
                  description: (
                    <Trans i18nKey="dns.domainVerification.recordMethodSteps.third" />
                  ),
                  note: (
                    <Trans i18nKey="dns.domainVerification.recordMethodSteps.noteForThird" />
                  ),
                },
              ]}
            />
          )}

          {modal.method === 'file' && (
            <DomainVerificationSteps
              steps={[
                {
                  description: (
                    <Trans
                      i18nKey="dns.domainVerification.fileMethodSteps.first"
                      components={{
                        a: (
                          <a
                            onClick={donwloadTxtFile}
                            className="text-sky-900 underline hover:cursor-pointer"
                          />
                        ),
                      }}
                    />
                  ),
                },
                {
                  description: (
                    <Trans i18nKey="dns.domainVerification.fileMethodSteps.second" />
                  ),
                  note: (
                    <Trans
                      i18nKey="dns.domainVerification.fileMethodSteps.noteForSecond"
                      values={{
                        domainPath: `${modal.hostName}/${assetVerificationId}.txt`,
                      }}
                      shouldUnescape
                    />
                  ),
                },
                {
                  description: (
                    <Trans i18nKey="dns.domainVerification.fileMethodSteps.third" />
                  ),
                  note: (
                    <Trans i18nKey="dns.domainVerification.fileMethodSteps.noteForThird" />
                  ),
                },
              ]}
            />
          )}
        </div>
      }
      primaryBtnText={i18next.t('buttons.verify')}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickPrimaryBtn={onClickVerifyDomain}
      onClickSecondaryBtn={() => setModal({showModal: false})}
      primaryButtonLoading={isLoading(loadingState.addDomain, false)}
      disablePrimaryBtn={modal.method === ''}
      onCloseModal={() => setModal({showModal: false})}
      width="max-w-xl"
    />
  )
}

const DomainVerificationSteps = ({steps}) => (
  <div className="mt-8">
    <ol className="flex flex-col gap-4">
      {steps.map((step, index) => (
        <li key={index} className="flex items-start">
          <div className="w-6 h-6 bg-sky-200/50 text-sky-900 font-medium rounded-full text-center mr-3">
            {index + 1}
          </div>
          <div>
            <div>{step.description}</div>
            <div className="text-xs">{step.note}</div>
          </div>
        </li>
      ))}
    </ol>

    <div className="text-xs mt-6">
      <Trans
        i18nKey="dns.domainVerification.support"
        components={{
          a: (
            <a
              href="mailto: support@unxpose.com"
              className="text-sky-900 font-medium"
              rel="noreferrer"
            />
          ),
        }}
      />
    </div>
  </div>
)

VerifyDomainModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}

DomainVerificationSteps.propTypes = {
  steps: PropTypes.array,
}

export default VerifyDomainModal
