import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams, useNavigate, Link} from 'react-router-dom'

import {register, loading, verifySession} from 'actions'
import {languages} from 'constant'

import Logo from 'res/brand/logo.svg'
import Spinner from '../partials/Spinner'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import {Page} from 'components/partials/Page'
import {getDefaultLanguage, getLocaleFromStorage, isLoading} from 'utils'

const Register = () => {
  const params = useParams()
  const navigate = useNavigate()

  const initialFormState = {
    id: params.id || false,
    firstName: '',
    lastName: '',
    position: '',
    locale: getLocaleFromStorage('i18nextLng'),
    password: '',
    passwordConfirmation: '',
    termsAcceptance: '',
  }

  const [user, setUser] = useState(initialFormState)
  const [passwordConfirmationMatches, setPasswordConfirmationMatches] =
    useState(true)
  const [validInvite, setInviteValidity] = useState()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const successState = useSelector(state => state.success)
  const errorState = useSelector(state => state.error)
  const inviteState = useSelector(state => state.invite)

  const dispatch = useDispatch()

  const handleInputChange = event => {
    setPasswordConfirmationMatches(true)

    const {name, value} = event.target
    setUser({...user, [name]: value})

    if (event.target.name === 'termsAcceptance') {
      setUser({...user, termsAcceptance: event.target.checked})
    }
  }

  const handleSelectChange = event => {
    const {value} = event

    i18next.changeLanguage(value)
    setUser({...user, locale: value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (validInvite && user.password !== user.passwordConfirmation)
      return setPasswordConfirmationMatches(false)

    dispatch(loading({register: true}))

    setPasswordConfirmationMatches(true)

    dispatch(register({...user, _csrf: csrfState}))
  }

  useEffect(() => {
    if (inviteState) {
      if (inviteState.userExists) navigate(`/accept/${params.id}`)
      else setInviteValidity(true)
    }
  }, [inviteState])

  useEffect(() => {
    if (params.id) dispatch(verifySession(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (successState) {
      navigate('/login')
      setUser(initialFormState)
    }
  }, [dispatch, successState])

  return (
    <Page pageTitle={i18next.t('pageTitles.registration')} hideHeader>
      <div className="roboto fixed right-0 flex flex-col items-center bg-white text-sideral-700 lg:max-w-xl w-full h-full py-12 overflow-auto z-50 px-4 md:px-24">
        <div className="font-light mb-10 text-center uppercase text-4xl text-gray-600">
          <img
            src={Logo}
            alt="Unxpose"
            className="mx-auto cursor-pointer w-56 "
          />
        </div>
        {validInvite ? (
          <>
            <div className="dm-mono text-sideral-700 font-bold mb-5">
              {i18next.t('titles.completeRegistration')}
            </div>
          </>
        ) : (
          <></>
        )}
        <form className="w-full" onSubmit={handleSubmit}>
          {validInvite ? (
            <div className="w-full">
              <PrimaryInput
                testId="email-input"
                text={i18next.t('labels.email')}
                type="text"
                name="email"
                value={inviteState.email}
                margin="mb-5"
                disabled
              />

              <PrimaryInput
                testId="first-name-input"
                text={i18next.t('labels.firstName')}
                placeholder={i18next.t('placeholders.firstName')}
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleInputChange}
                required={true}
                margin="mb-5"
              />

              <PrimaryInput
                testId="last-name-input"
                text={i18next.t('labels.lastName')}
                placeholder={i18next.t('placeholders.lastName')}
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleInputChange}
                required={true}
                margin="mb-5"
              />

              <PrimaryInput
                testId="job-title-input"
                text={i18next.t('labels.jobTitle')}
                placeholder={i18next.t('placeholders.jobTitle')}
                type="text"
                name="position"
                value={user.position}
                onChange={handleInputChange}
                required={true}
                margin="mb-5"
              />

              <div className="mb-5">
                <PrimarySelect
                  text={i18next.t('labels.language')}
                  name="locale"
                  defaultValue={getDefaultLanguage('i18nextLng')}
                  options={languages}
                  onChange={handleSelectChange}
                />
              </div>

              <PrimaryInput
                testId="password-input"
                text={i18next.t('labels.password')}
                placeholder={i18next.t('placeholders.minCharacters')}
                type="password"
                name="password"
                value={user.password}
                onChange={handleInputChange}
                minLength={10}
                required={true}
                autoComplete={'new-password'}
                margin="mb-5"
              />

              <PrimaryInput
                testId="password-confirmation-input"
                text={i18next.t('labels.passwordConfirmation')}
                placeholder={i18next.t('placeholders.passwordConfirmation')}
                name="passwordConfirmation"
                type="password"
                value={user.passwordConfirmation}
                onChange={handleInputChange}
                hasError={!passwordConfirmationMatches}
                errorMsg={i18next.t(
                  'warnings.passwordConfirmationDontMatchRegister'
                )}
                minLength={10}
                required={true}
                autoComplete="new-password"
                margin="mb-5"
              />
              <div className="flex mb-6 pl-1">
                <input
                  data-testid="terms-checkbox"
                  className="form-checkbox h-5 w-5 text-sky-500"
                  checked={user.termsAcceptance}
                  name="termsAcceptance"
                  onChange={handleInputChange}
                  required
                  type="checkbox"
                />
                <span className="flex items-center ml-2">
                  <div className="mr-1">
                    {i18next.t('checkboxes.termsAndConditions')}
                  </div>
                  <Link
                    to="/terms"
                    target="_blank"
                    className="text-sky-500 hover:text-sky-800 underline">
                    {i18next.t('links.termsAndConditions').toUpperCase()}{' '}
                  </Link>
                </span>
              </div>
              <div className="pt-2">
                <PrimaryButton
                  testId="register-btn"
                  text={i18next.t('buttons.createAccount')}
                  type="submit"
                  size="full"
                  loading={isLoading(loadingState.register, false)}
                />
              </div>
            </div>
          ) : (
            <>
              {errorState ? (
                <div className="text-center">
                  {i18next.t('errors.invalidOrExpiredInvite')}
                </div>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </form>
      </div>
    </Page>
  )
}

Register.propTypes = {
  history: PropTypes.object,
}

export default Register
