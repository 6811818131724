import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {Outlet, useLocation} from 'react-router-dom'
import {isLoading, restrictedRoles} from 'utils'

import Menu from './Menu'
import Footer from './partials/Footer'
import Spinner from './partials/Spinner'
import PageNotFound from 'components/PageNotFound'
import PlanRenewalModal from './partials/PlanRenewalModal'
import PlanRestrictionModal from './partials/PlanRestrictionModal'
import MemberGetMemberModal from './partials/MemberGetMemberModal'
import ProcessingAlert from './partials/ProcessingAlert'
import {SurveyModal} from './partials/SurveyModal'
import AutoSubscriptionModal from './settings/AutoSubscriptionModal'

export default function ProtectedRoutes({menuProps}) {
  const location = useLocation()

  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)

  const getPageContent = () => {
    const restrictedRole = restrictedRoles.find(
      role => role.name === userState.role
    )

    if (restrictedRole) {
      const hasAccess = restrictedRole.allowedPaths.some(path =>
        location.pathname.includes(path)
      )

      return hasAccess ? <Outlet /> : <PageNotFound />
    }

    return <Outlet />
  }

  if (isLoading(loadingState.user)) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <Menu {...menuProps} />
      <ProcessingAlert />
      <SurveyModal />
      <PlanRenewalModal />
      <PlanRestrictionModal />
      <AutoSubscriptionModal />
      <MemberGetMemberModal />

      <main id="authenticated-page-layout">
        <div className="flex flex-col justify-between min-h-screen px-4 pt-4 md:pr-4 md:pl-24 lg:pl-68 xxl:mx-auto w-full max-w-screen-xxl">
          <div className="h-full">{getPageContent()}</div>
          <Footer />
        </div>
      </main>
    </>
  )
}

ProtectedRoutes.propTypes = {
  menuProps: PropTypes.object,
}
