import React, {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import VerifyDomainModal from './VerifyDomainModal'
import {clearSuccess} from 'actions'
import {DefaultModal} from 'components/partials/DefaultModal'

const AddDomainModal = ({modalVisibility, setModalVisibility}) => {
  const dispatch = useDispatch()

  const domainInput = useRef()

  const initialFormState = {hostname: ''}

  const [form, setForm] = useState(initialFormState)
  const [verificationModal, setVerificationModal] = useState({
    hostName: '',
    method: '',
    showModal: false,
  })

  const handleInviteChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const onClickAddDomain = () => {
    dispatch(clearSuccess())

    setModalVisibility('hidden')

    setVerificationModal({
      ...verificationModal,
      hostName: form.hostname,
      showModal: true,
    })
  }

  const closeModal = e => {
    const inviteSectionModal = document.querySelector('#add-domain-modal')
    if (e.target === inviteSectionModal) {
      setModalVisibility('hidden')
    }
  }

  useEffect(() => {
    if (modalVisibility === 'hidden') {
      setForm(initialFormState)
      return
    }

    if (domainInput.current) domainInput.current.focus()
  }, [modalVisibility])

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
  }, [])

  return (
    <>
      {modalVisibility !== 'hidden' && (
        <DefaultModal
          id="add-domain-modal"
          title={i18next.t('titles.addDomain')}
          body={
            <PrimaryInput
              testId="add-domain-input"
              text={i18next.t('misc.domain')}
              type="text"
              name="hostname"
              required={true}
              value={form.hostname || ''}
              onChange={handleInviteChange}
              margin="mb-6"
              reference={domainInput}
            />
          }
          primaryBtnText={i18next.t('buttons.next')}
          secondayBtnText={i18next.t('dns.addDomainBtn.cancel')}
          onClickPrimaryBtn={onClickAddDomain}
          onClickSecondaryBtn={() => {
            setModalVisibility('hidden')
          }}
          disablePrimaryBtn={!form.hostname}
        />
      )}

      {verificationModal.showModal && (
        <VerifyDomainModal
          modal={verificationModal}
          setModal={setVerificationModal}
        />
      )}
    </>
  )
}

AddDomainModal.propTypes = {
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
}

export default AddDomainModal
